import {
  isPropertyWithVerify,
  isBoolean,
  isNonEmptyString,
  validateIsObject,
  validateNotNull,
} from '../guards';
import { Authority, validateAuthorityOf } from './authority';

export interface AgreementsAndRegulatory {
  certification?: string;
  genre: Authority<Nullable<OfcomGenre>>;
  outsideOfLondon: Authority<Nullable<boolean>>;
}

export const validateAgreementsAndRegulatory = (argument: any) => {
  validateNotNull(argument, 'Invalid AgreementsAndRegulatory, value is null');
  validateIsObject(
    argument,
    'Invalid AgreementsAndRegulatory, value is not an object'
  );
  if (argument.certification != null) {
    isPropertyWithVerify(
      argument.certification,
      isNonEmptyString,
      'Invalid AgreementsAndRegulatory.certification, should be string'
    );
  }
  if (argument.genre?.value != null) {
    isPropertyWithVerify(
      argument.genre,
      validateAuthorityOf('genre', validateOfcomGenre),
      'Invalid AgreementsAndRegulatory.genre, should be OfcomGenre'
    );
  }
  if (argument.outsideOfLondon?.value != null) {
    isPropertyWithVerify(
      argument.outsideOfLondon,
      validateAuthorityOf('outsideOfLondon', isBoolean),
      'Invalid AgreementsAndRegulatory.outsideOfLondon, should be boolean'
    );
  }
  return true;
};

export interface OfcomGenre {
  superGenre: string;
  subGenre: string;
}

export const validateOfcomGenre = (argument: any) => {
  validateNotNull(argument, 'Invalid OfcomGenre, value is null');
  isPropertyWithVerify(
    argument.superGenre,
    isNonEmptyString,
    'Invalid OfcomGenre.superGenre, should be string'
  );
  isPropertyWithVerify(
    argument.subGenre,
    isNonEmptyString,
    'Invalid OfcomGenre.subGenre, should be string'
  );
  return true;
};
